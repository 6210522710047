<template>
  <Title :text="certificationLabel" />
  <section class="certification-section" v-for="(certification, index) in certifications" :key="index">
   <a   :href="certification.link" target="_blank">
      <Headline :text="certification.title" />
      <Paragraph class="text-bold" :text="certification.institutionName" />
      <Paragraph :text="certification.date" />
    </a>

      <Paragraph class="description" :text="certification.description" />
  </section>
</template>

<script>
import Title from "@/components/Title.vue";
import Headline from "@/components/Headline.vue";
import Paragraph from "@/components/Paragraph.vue";

export default {
  Name: "Certifications",

  components: { Title, Headline, Paragraph },

  computed: {
    currentLanguage() {
      return this.$store.state.currentLanguage;
    },

    certificationLabel() {
      return this.currentLanguage.resumeLabels.certifications;
    },

    certifications() {
      return this.currentLanguage.data.certifications;
    },
  },
};
</script>

<style scoped>
.certification-section {
  margin-bottom: 2em;
  margin-left: 0em;
}

.certification-section:last-child {
  margin-bottom: 0;
}
.text-bold{
  /* margin-left: 0px!important; */
  font-weight: bold;
  
}
.description{
font-size: 14px;
}
</style>
