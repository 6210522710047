<template>
 <div class="a4">
  <div class="wrapper">
    <div class="header">
      <Header />
    </div>
    <div class="contacts">
      <Contacts />
    </div>
    <div class="profile">
      <Profile />
    </div>
    <div class="skills">
      <Skills />
    </div>
    
    <div class="education">
      <Education />
    </div>
    
    <div class="certifications">
      <Certifications />
    </div>
    <div class="experience">
      <Experience />
    </div>
    <!-- <div class="certifications1">
      <Certifications1 />
    </div> -->
   
  </div>
  </div>

</template>

<script>
import Contacts from "@/components/Contacts.vue";
import Profile from "@/components/Profile.vue";
import Header from "@/components/Header.vue";
import Skills from "@/components/Skills.vue";
import Experience from "@/components/Experience.vue";
import Education from "@/components/Education.vue";
import Certifications from "@/components/Certifications.vue";
// import Certifications1 from './Certifications1.vue';

export default {
  name: "Resume",

  components: { Contacts, Profile, Header, Skills, Experience, Education ,Certifications},
};
</script>

<style scoped>

.wrapper {
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-template-rows: 190px auto auto auto auto;
  grid-template-areas:
    "header header"
    "profile profile"
    "contacts education"
    /* "education experience" */
    "skills experience"
    "skills experience"
    "expertise experience"
    "certifications experience";
}

.contacts,
.skills,
/* .education, */
.expertise,
.certifications,
.certifications1,
.profile{
  padding: 0px 45px 0 45px;
  margin: 0px 0px 45px 0px;
}


.experience {
  padding: 0px 45px 0 0;
  margin: 0px 0px 45px 0px;
}
.certifications1 {
  padding: 0px 45px 0 0;
  margin: 0px 0px 45px 0px;
}
.experience,
.expertise {
  margin: 0;
}

.header {
  display: flex;
  grid-area: header;
  background-image: url("~@/assets/wave.svg");
  background-repeat: no-repeat;
}

.contacts {
  grid-area: contacts;
}

.profile {
  grid-area: profile;
}

.skills {
  grid-area: skills;
}

.experience {
  grid-area: experience;
}

.education {
  grid-area: education;
}

.certifications {
  grid-area: certifications;
  /* align-content: ce; */
  /* margin-left: auto;
  margin-right: auto; */
}
.certifications1 {
  grid-area: certifications1;
  /* align-content: ce; */
  /* margin-left: auto;
  margin-right: auto; */
}

.expertise {
  grid-area: expertise;
}

@media (max-width: 21cm) {
  .wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, auto);
    grid-template-areas:
      "header"
      "contacts"
      "profile"
      "experience"
      "skills"
      "education"
      "certifications"
      "expertise";
  }

  .header {
    background-image: url("~@/assets/wave-mobile.svg") !important;
  }

  .contacts,
  .skills,
  .profile,
  .experience,
  .expertise,
  .skills,
  .certifications {
    padding: 0px 20px 0 20px !important;
  }
  .education{
    padding-left: 20px;
    padding-bottom: 50px;
  }
}

@media print {
  .education {
    padding: 0px 20px 0 20px !important;
  }
  .wrapper {
    grid-template-columns: 2fr 2fr;

    grid-template-areas:
    "header header"
    "profile profile"
    "contacts education"
    "skills experience"
    "skills experience"
    "skills experience"
    "skills experience"
    "expertise experience"
    "certifications experience";
      
      
  }
  .wrapper{
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .header {
    display: flex;
    grid-area: header;
    background-image: url("~@/assets/wave.svg")!important;
    background-repeat: no-repeat;
     background-size: cover; /* O usa 100% para que se extienda a todo el ancho */
    background-position: center; /* Centra la imagen en el elemento */
    width: 100%;
  }
}

.a4 {
  background: #e4e4e6;
  display: block;
  margin: 0 auto;
  margin-top: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  width: 21cm;
  min-height: 29.7cm;
}

@media (max-width: 21cm) {
  .a4 {
    width: auto !important;
    min-height: auto !important;
    box-shadow: none !important;
    margin: 0 !important;
    margin-top: 0;
  }
}

@media print {
  .a4 {
    margin: 0;
    width: 25cm !important;
    box-shadow: none;
    /* height: 705mm; */
  }
  .person-photo-image {
  border-radius: 50%;
  height: 150px!important;
  width: auto!important;
}
.certifications{
  margin-top: 0!important;
}
}
</style>
