import enData from "../../resume/en-data.yaml";
import esData from "../../resume/es-data.yaml";
// import frData from "../../resume/fr-data.yaml";

export const languages = [
  {
    name: "English",
    code: "en",
    data: enData,
    resumeLabels: {
      contacts: "Contacts",
      profile: "Profile",
      skills: "Skills",
      experience: "Experience",
      professional: "Professional",
      education: "Education",
      certifications: "Certifications",
    },
  },
  {
    name: "Español",
    code: "es",
    data: esData,
    resumeLabels: {
      contacts: "Contactos",
      profile: "Perfil",
      skills: "Habilidades",
      experience: "Experiencia",
      professional: "Profesional",
      education: "Educación",
      certifications: "Certificaciones",
    }
  },
  // {
  //   name: "Français",
  //   code: "fr",
  //   data: frData,
  //   resumeLabels: {
  //     contacts: "Contacts",
  //     profile: "Profil",
  //     skills: "Compétences",
  //     experience: "Expérience",
  //     professional: "professionnelle",
  //     education : "Formation"
  //   }
  // },
];

export function getCurrentLanguage() {
  return languages[0];
}

export function getLanguages() {
  return languages;
}
