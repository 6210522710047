<template>
  <div class="currentBackground">
    <div class="container mx-auto px-4 py-10">
      <div class="text-center">
        <h2 class="text-3xl font-bold mb-4">Documentation</h2>
        <p class="text-lg text-gray-700 mb-6">
          Explore my santos labs documentation, where you can find hands-on guides to learn Kubernetes, Celery, FastAPI, and others from scratch.
        </p>
        
        <a target="_blank" href="https://docs.santosdevco.com/" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300">
          Go to Documentation
        </a>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'DocumentationSection',
  // Other component options
};
</script>

<style scoped>
/* Add any additional styles if needed */
.currentBackground {
  /* --main-bg-color: #61ad1e;  */
  position: relative;
  /* min-height: 30vh;  */
  background-color: var(--main-bg-color); /* Use the CSS variable for the background color */
  overflow: hidden;
}

.currentBackground::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.155); /* White with transparency */
  /* clip-path: polygon(20% 0, 100% 0, 100% 100%, 80% 100%); */
  clip-path: polygon(0 0, 100% 0, 100% 100%);

  z-index: 1;
}

</style>
