<template>
 <div id="app">
    <router-view></router-view> <!-- Routed components will be displayed here -->
  </div>
</template>

<script>

export default {
  name: "App",
  components: {
  },
};
</script>

<style>
@import '~@/assets/styles/fonts.css';

#app {
  box-sizing: border-box;
  font-family: "Raleway", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: "Raleway", sans-serif !important;
}

body {
  margin: 0;
  background: rgb(204, 204, 204);
}

</style>
