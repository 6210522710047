<template>
  <section id="projects" class="py-10">
    <div class="container mx-auto px-4">
      <h2 class="text-4xl font-bold text-center mb-8">Projects</h2>
      <div class="flex flex-wrap justify-center gap-4">
        <div
          v-for="(project, index) in projects"
          :key="project.title"

           @mouseover="hoveredProject = index"
          @mouseleave="hoveredProject = null"
          :class="['currentBackground'+ (index % 2), hoveredProject === index ? 'hovered' : '', 'p-2 mb-3 max-w-sm rounded overflow-hidden transform transition duration-500 hover:scale-105 hover:shadow-2xl']"
        >
          <img
            class="imageClass w-full bg-gray-400 rounded p-4 px-auto"
            :src="project.imageUrl"
            :alt="project.title"
          />
          <div class="w-full content-center flex justify-center items-center">
            <span
              class="bg-gray-800 rounded-b-md px-4 py-1 text-base font-semibold text-gray-200"
              >{{ project.year }}</span
            >
            
          </div>
          <div class="px-6 py-4">
            <div class="font-bold text-center text-gray-200 text-2xl mb-2"
            :class="hoveredProject === index ? 'hovered-text' : ''"
            >
              {{ project.title }}
            </div>

            <p class="text-gray-200 text-base text-center"  :class="hoveredProject === index ? 'hovered-text' : ''">
              {{ project.description }}
            </p>
            <div class="flex flex-wrap gap-3 justify-center mt-4">
              <div
                v-for="rol in project.roles"
                :key="rol"
                class="flex items-center mx-2"
              >
                <span class="ml-2 text-sm text-gray-200 bg-gray-900 px-2 rounded  text-center" >{{ rol }}</span>
              </div>
            </div>
          </div>
          
          <div v-if="project.technologies.length > 1">
          <carousel class="  mx-4 w-auto" 
              :wrapAround="true" 
              :autoplay="900"
              :itemsToShow="project.technologies.length>5?5:project.technologies.length-1">
            <slide class="px-0 mx-0" v-for="(technology, indext) in project.technologies" :key="indext">
              <div class=" p-0 m-0 flex flex-col items-center">
                <img :src="technology.icon" :alt="technology.name" class="h-auto w-6"  :class="hoveredProject === index ? 'hovered-icon' : ''">
                <div class="text-center mt-2 ">{{ technology.name }}</div>
              </div>
            </slide>
          </carousel>
          </div>
          <div v-else>
            <div class="flex justify-center mt-4">
              <div
                v-for="tech in project.technologies"
                :key="tech.name"
                class="flex items-center mx-2"
              >
                <img :src="tech.icon" :alt="tech.name" class="h-6 w-6" />
                <span class="ml-2 text-sm text-gray-200">{{ tech.name }}</span>
              </div>
            </div>
          </div>
          <div class="absolute bottom-2 left-2 z-10 flex justify-between items-center px-6 py-4 w-full  " v-show="hoveredProject === index">
            <!-- GitHub icon -->
            <a :href="project.repo" target="_blank" v-if="project.repo">
              <img src="@/assets/icons/github-mark.svg" alt="GitHub" class="h-10 w-10 " />
            </a>
            
            <!-- External URL icon -->
            <a :href="project.url" target="_blank" v-if="project.external">
              <img  src="@/assets/icons/external-link.svg" alt="External Link" class="h-10 w-10" />
            </a>
            <a v-if="!project.external && project.url"   target="_blank" :href="project.url" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300">
              Go to Documentation
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
export default {
  name: "ProjectsSection",
  components: {
   Carousel,
    Slide
  },
  data() {
  return {
     hoveredProject: null,
    projects: [
    {
        title: 'Sonar Qube Sync  Visual Studio Extension',
        year: '2024',
        imageUrl: require('@/assets/icons/sonarsync.svg'),
        description: 'A Visual Studio extension to search for SonarQube security hotspots and issues and display them in the Issues vs. Security section.',
        roles: ["Developer","Architect"],
        url:"https://marketplace.visualstudio.com/items?itemName=SantosDevCO.sonarext",
        repo: 'https://github.com/SantiagoAndre/vs-extension-sonar-server-sync',
        technologies: [
          { name: 'Typescript', icon: require('@/assets/icons/typescript.svg') },
          { name: 'SonarQube', icon: require('@/assets/icons/sonarqube.svg') },
          { name: 'VS Code', icon: require('@/assets/icons/vscode.svg') },
          // { name: 'Docker', icon: require('@/assets/icons/docker-svgrepo-com.svg') },
        ],
      },
      {
        title: 'ASVS Manual testing APP',
        year: '2024',
        imageUrl: require('@/assets/icons/owasp.svg'),
        // description: 'This application stems from my degree project research, designed to evaluate ASVS good practices across system components. Users navigate through a series of questions to assess security features of each asset, identifying either strong practices or vulnerabilities. It provides detailed feedback and navigation options, enhancing security assessment and management for IT professionals. ',
        description: 'This tool assesses ASVS good practices within system components, offering clear feedback on security measures and vulnerabilities.',

        
        roles: ["Developer","Architect","Reseacher"],
        url:"https://master--sequitityquiz.netlify.app",
        external: true,

        // repo: 'https://github.com/SantiagoAndre/vs-extension-sonar-server-sync',
        technologies: [
        { name: 'Vite', icon: require('@/assets/icons/vitejs-svgrepo-com.svg') },
          { name: 'Vue.js', icon: require('@/assets/icons/vue-9-logo-svgrepo-com.svg') },
          { name: 'Netlify', icon: require('@/assets/icons/netlify-icon.svg') }, // Add your Netlify icon here
          { name: 'Tailwind CSS', icon: require('@/assets/icons/tailwindcss-icon.svg') }, // Add your Tailwind CSS icon here
        ],
      },
      {
        title: 'Compensave',
        year: '2018 - 2023',
        imageUrl: require('@/assets/icons/compensave.svg'),
        description: 'An online Carbon Footprint Platform. ',
        roles: ["Lead","Developer","Sys Admin","DevOps"],
        url: 'https://compensave.co',
        external: true,
        technologies: [
          { name: 'Django', icon: require('@/assets/icons/django-svgrepo-com.svg') },
          { name: 'ViteJS', icon: require('@/assets/icons/vitejs-svgrepo-com.svg') },
          { name: 'Vue.js', icon: require('@/assets/icons/vue-9-logo-svgrepo-com.svg') },
          { name: 'PostgreSQL', icon: require('@/assets/icons/postgresql-icon.svg') },
          { name: 'Digital Ocean', icon: require('@/assets/icons/digital-ocean-svgrepo-com.svg') },
          // Assume you have icons for Nginx and Dokku
        ],
      },
      {
        title: 'Mkdocs labs from Scratch',
        year: '2021',
        imageUrl: require('@/assets/icons/kubernetes-svgrepo-com.svg'),
        description: 'Beautifully crafted documentation to learn topics like Kubernetes, Celery, FastAPI, Scrapping ...',
        roles: ["Writer","Architecture Designer","Infrastructure Designer"],

        url: 'https://docs.santosdevco.com',
        repo:"https://github.com/SantiagoAndre/santos-docs",
        external: false,
        technologies: [
          { name: 'MkDocs', icon: require('@/assets/icons/mkdocs.svg') }, // Add your MkDocs icon here
          { name: 'Kubernetes', icon: require('@/assets/icons/kubernetes-svgrepo-com.svg') },

          { name: 'Celery', icon: require('@/assets/icons/celery2.svg') },
          { name: 'FastAPI', icon: require('@/assets/icons/fastapi-1.svg') },
        ],
      },
      {
        title: 'Celery Fast API Labs',
        year: '2020',
        imageUrl: require('@/assets/icons/celery1.svg'),
        description: 'A beautiful repository with excellent documentation and diagrams to learn Celery with FastAPI from scratch.',
        roles: ["Writer","Architect","Designer"],

        repo: 'https://github.com/SantiagoAndre/celery-fast-api-labs/',
        technologies: [
          { name: 'Celery', icon: require('@/assets/icons/celery2.svg') },
          { name: 'FastAPI', icon: require('@/assets/icons/fastapi-1.svg') },
          // { name: 'SQL', icon: require('@/assets/icons/sql-icon.svg') }, // Add your SQL icon here
          { name: 'Sockets', icon: require('@/assets/icons/sockets-icon.svg') }, // Add your Sockets icon here
          { name: 'Docker', icon: require('@/assets/icons/docker-svgrepo-com.svg') },
        ],
      },
      {
        title: 'Google Maps Scrapper',
        year: '2023',
        imageUrl: require('@/assets/icons/google-maps-platform-svgrepo-com.svg'),
        description: 'A Python framework to scrape data from Google Maps, storing information in MongoDB, with support for multiple Docker instances.',
        roles: ["Python Developer","Architect"],

        repo: 'https://github.com/SantiagoAndre/google-maps-scrapping',
        technologies: [
          { name: 'Python', icon: require('@/assets/icons/python-svgrepo-com.svg') },
          { name: 'MongoDB', icon: require('@/assets/icons/mongodb-svgrepo-com.svg') },
          { name: 'Docker', icon: require('@/assets/icons/docker-svgrepo-com.svg') },
        ],
      },
      {
        title: 'Mentor',
        year: 'active',
        imageUrl: require('@/assets/icons/mentor.svg'),
        description: 'Landing Page for the Mentorlid Platform, an Online Classes Company',
        roles: ["Lead","Developer","DevOps","Frontend Designer"],

        url: 'https://tumentor.netlify.app/',
        external: true,
        technologies: [
          { name: 'Vite', icon: require('@/assets/icons/vitejs-svgrepo-com.svg') },
          { name: 'Vue.js', icon: require('@/assets/icons/vue-9-logo-svgrepo-com.svg') },
          { name: 'Netlify', icon: require('@/assets/icons/netlify-icon.svg') }, // Add your Netlify icon here
          { name: 'Tailwind CSS', icon: require('@/assets/icons/tailwindcss-icon.svg') }, // Add your Tailwind CSS icon here
        ],
      },
      
      // Add additional projects here...
    ],
  };
},

  
};
</script>

<style scoped>
/* Scoped styles here */

/* Scoped styles here */
.currentBackground1,
.currentBackground0 {
  --main-bg-color: #023e8a;
  position: relative;
  /* min-height: 30vh;  */
  background-color: var(
    --main-bg-color
  ); /* Use the CSS variable for the background color */
  overflow: hidden;
  width: 400px;
}

.currentBackground1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.09); /* White with transparency */
  /* clip-path: polygon(100% 100%, 0 100%, 0 0); */
  clip-path: polygon(
    0 80%,
    0 21%,
    35% 0,
    100% 0,
    100% 20%,
    0 80%,
    75.5% 100%,
    100% 85%,
    100% 100%,
    75.5% 100%
  );
  z-index: 1;
}
.currentBackground0::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.09); /* White with transparency */
  clip-path: polygon(
    0 0,
    0 20%,
    35% 0,
    100% 0,
    100% 22%,
    0 84%,
    0 100%,
    72% 100%,
    100% 82%,
    100% 20%,
    100% 0
  );
  z-index: 1;
}
.imageClass {
  height: 240px;
}
.carousel__slide--active div img {
  /* Add your styles here */
    width: 50px; /* Example: make the image take up 100% of its parent's width */
    height: auto; /* Example: scale the height automatically to maintain the aspect ratio */
    /* object-fit: cover; Example: cover the area without distorting the aspect ratio */
    /* border-radius: 10px; Example: give the image rounded corners */
}
.hovered{
   background-color: rgba(255, 255, 255, 0.2); /* Lighten the background on hover */
  padding-bottom: 70px;
}
.hovered-text{
  color: black;
}
.hovered-icon{
  width:40px;
}
</style>
