<template>
  <Title :text="educationLabel" />
  <section class="education-section" v-for="(education, index) in educations" :key="index">
    <Headline class="letter-space" :text="education.degreeTitle" />
    <Headline v-if="education.status" class="text-bold letter-space1" :text="education.status" />
    <Paragraph class="letter-space2 text-black" :text="education.institutionName" />
    <Paragraph class="letter-space2 text-black" :text="education.studyDirection" />
    <!-- <Paragraph text="Colombia" /> -->
    <Paragraph  class="letter-space2" :text="education.dates" />
  </section>
</template>

<script>
import Title from "@/components/Title.vue";
import Headline from "@/components/Headline.vue";
import Paragraph from "@/components/Paragraph.vue";

export default {
  Name: "Education",

  components: { Title, Headline, Paragraph },

  computed: {
    currentLanguage() {
      return this.$store.state.currentLanguage;
    },

    educationLabel() {
      return this.currentLanguage.resumeLabels.education;
    },

    educations() {
      return this.currentLanguage.data.education;
    },
  },
};
</script>

<style scoped>
.education-section {
  margin-bottom: 2em;
  margin-left: 0em;
}

.education-section:last-child {
  margin-bottom: 0;
}
.text-bold{
  margin-left: 0px!important;
  /* font-weight: bold;
  text-transform: uppercase; */
  /* padding-top: 20; */
}
.letter-space{
  letter-spacing: 6px;
}
.letter-space1{
  letter-spacing: 4px;
}
.letter-space2{
  letter-spacing: 2px;
}
.text-black{
  color: black;
}
</style>
