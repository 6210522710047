<template>
  <section id="profile" class="bg-gray-300 py-10">
    <!-- <h2 class=" text-center text-2xl font-bold my-5">ABOUT ME</h2> -->
    <h2 class="text-center text-4xl font-bold mb-8">About Me</h2>

    <div class="container mx-auto px-4">
      <div class="flex flex-col md:flex-row items-center lg:mx-20">
        <div class="flex-shrink-0 mb-6 md:mb-0 md:mr-6">
          <img
            class="rounded-full w-40 shadow-md border-4 border-white"
            src="@/assets/photo.jpeg"
            alt="Profile Picture"
          />
        </div>
        <div
          class="w-auto bg-yellow-200 p-6 rounded-lg shadow-md currentBackground"
        >
          <div class="flex justify-between items-center">
            <div></div>
            <div class="flex space-x-2">
              <!-- macOS-style window control buttons -->
              <div class="w-3 h-3 bg-red-500 rounded-full"></div>
              <div class="w-3 h-3 bg-yellow-500 rounded-full"></div>
              <div class="w-3 h-3 bg-green-500 rounded-full"></div>
            </div>
          </div>
          <p class="text-gray pt-5 text-justify">
            👋Hello! Welcome to my portfolio. I'm a Software Engineer passionate about the software industry, with strong affinity for Python, Linux, Docker, Vue.js, AWS, and Mongo. Constantly drive to expand my learning and I'm skilled at automating and improving processes. One of my strengths is technical documentation and creating C4 diagrams, which has allowed me to clarify and effectively communicate the system architecture and design in my projects. Working as freelancer I have embraced diverse roles, including team leader, requirements manager, designer, architect, full-stack developer, systems administrator, and DevOps. I have a particular interest in scraping projects to automate data extraction. I have worked on multiple projects, obtaining successful results in cloud-based, real-time, asynchronous, serverless architectures, among others.💜
          </p>
          <div class="relative p-6 rounded-lg mx-0 my-0">
            <!-- ... content ... -->
            <!-- 'Go to CV' button pushed to the bottom-right corner -->

            <router-link
              to="/resume"
              class="absolute bottom-0 right-0 ml-auto bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              Go to Resume
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutSection",
  // Component data and methods here...
};
</script>

<style scoped>
/* Scoped styles here */
.currentBackground {
  --main-bg-color: #dab600;
  position: relative;
  /* min-height: 30vh;  */
  background-color: var(
    --main-bg-color
  ); /* Use the CSS variable for the background color */
  overflow: hidden;
}

.currentBackground::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.155); /* White with transparency */
  clip-path: polygon(80% 0, 20% 100%, 0 100%, 0 0);
  z-index: 1;
}
</style>
