<template>
  <HomeSection/>
    <ProfileSection/>
    
    <TechnologiesSection/>
    <ProjectsSection/>
<DocumentationSection/>
    <!-- Projects Section -->
    
    
</template>

<script>

// add this
import '../taiwindindex.css'
import ProfileSection from "@/components/ProfileSection.vue";
import ProjectsSection from "@/components/ProjectsSection.vue";
import TechnologiesSection from "@/components/TechnologiesSection.vue";
import DocumentationSection from "@/components/DocumentationSection.vue";
import HomeSection from "@/components/HomeSection.vue";
export default {
  name: 'PortfolioComponent',
  components:{ProfileSection,ProjectsSection,TechnologiesSection,DocumentationSection,HomeSection}
  // You can add your JavaScript logic here
};
</script>

<style scoped>
/* You can add additional CSS styles here if needed */
</style>
