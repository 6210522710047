<template>
  <div class="min-h-screen flex lg:flex-row px-5 lg:px-0 flex-col gap-32 items-center justify-center currentBackground">
    <div class="lg:flex-initial lg:w-24"></div>
    <div class="text-center flex-initial">
      <h1 class="text-6xl text-white font-bold mb-4">
        Santiago Ramirez's Portfolio
      </h1>
      <h2 class="text-3xl text-white font-semibold mb-6">
        Software Engineer | Python, DevOps, and More
      </h2>
      <button
        @click="scrollToProfile"
        class="text-teal-900 bg-white hover:bg-gray-200 font-bold py-2 px-6 rounded shadow-lg transition duration-300 cursor-pointer"
      >
        View My Work
      </button>
    </div>
    <nav class="social-links social-links__row w-full lg:w-28 flex flex-row lg:flex-col items-center  gap-8 mx-auto justify-center">
      <li class="social-links__list">
        <a class="social-links__link app-link" rel="noopener noreferrer" target="_blank" title="Visit my GitHub profile" :href="links.github">
          <img class="w-12" src="@/assets/icons/github.svg" alt="GitHub Icon">
        </a>
      </li>
      <li class="social-links__list">
        <a class="social-links__link app-link" rel="noopener noreferrer" target="_blank" title="View my projects on GitLab" :href="links.gitlab">
          <img class="w-12" src="@/assets/icons/gitlab-logo-500.svg" alt="GitLab Logo">
        </a>
      </li>
      <li class="social-links__list">
        <a class="social-links__link app-link" rel="noopener noreferrer" target="_blank" title="Connect with me on LinkedIn" :href="links.linkedin">
          <img class="w-12" src="@/assets/icons/linkedin2.svg" alt="LinkedIn Icon">
        </a>
      </li>
      <li class="social-links__list">
        <a class="social-links__link app-link" rel="noopener noreferrer" target="_blank" title="Email me" :href="'mailto:'+links.mail">
          <img src="@/assets/icons/mail.svg" alt="Email Icon">
        </a>
      </li>
    </nav>
  </div>
</template>

<script>
export default {
  name: "HomeSection",
  data(){
    return {
      links:{
        'linkedin':'https://www.linkedin.com/in/santosdev',
        'github':'https://github.com/santiagoAndre/',
        'gitlab':'https://gitlab.com/santiagoAndre/',
        'mail':'projects@santosdevco.com'
      }
    }
  },
  methods: {
    scrollToProfile() {
      // Use the Vue nextTick function to wait for the DOM to update
      this.$nextTick(() => {
        const profileSection = document.getElementById("projects");
        if (profileSection) {
          profileSection.scrollIntoView({ behavior: "smooth" });
        }
      });
    },
  },
  // Other component options
};
</script>

<style scoped>
.social-links {
  list-style-type: none; /* Removes bullet points */
  padding-left: 0; /* Removes any padding that might be causing indentation */
}

.currentBackground {
  --main-bg-color: #023e8a;
  position: relative;
  /* min-height: 30vh;  */
  background-color: var(
    --main-bg-color
  ); /* Use the CSS variable for the background color */

  /* position: relative; This ensures that the pseudo-element is positioned in relation to this div */
}

.currentBackground::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.09); /* White with transparency */
  clip-path: polygon(80% 0, 0 0 , 0 100%, 20% 100%);
  z-index: 0; /* Lower z-index so content appears above */
}

.currentBackground > * {
  position: relative;
  z-index: 1; /* Higher z-index so these elements appear above the pseudo-element */
}

.social-links__link:hover img {
  opacity: 0.7; /* The image becomes slightly transparent on hover */
  transition: opacity 0.3s ease-in-out; /* Smooth transition for the opacity change */
}

/* Optionally: scale the image up slightly to give a 'pop' effect */
.social-links__link:hover img {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

</style>
