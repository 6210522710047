<template>
  <section id="technologies" :style="computedStyle" class="currentBackground">
    <h2 class="text-center text-4xl font-bold my-4">{{currentType}} Skills</h2>
    <div class="flex justify-center gap-4">
      <!-- Your group buttons here -->
    </div>
    <!-- Swiper container -->
  <div class="mb-2">
    <carousel class=" my-8" :autoplay="700"
              :wrapAround="true" 
              :breakpoints="breakpoints"
      @update:modelValue="onSlideChange">
      <slide class="px-0 mx-0" v-for="(technology, index) in technologies" :key="index">
        <div class=" p-0 m-0 flex flex-col items-center">
          <img :src="technology.icon" :alt="technology.name" class="h-12 w-12">
          <div class="text-center mt-2">{{ technology.name }}</div>
        </div>
      </slide>
    </carousel>
  </div>
  </section>
</template>

<script>
import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
export default {
  name: 'TechnologiesSection',
  components: {
   Carousel,
    Slide
  },
  data() {
    return {
      technologies: [
      // Backend Technologies
      { name: 'Django', icon: require('@/assets/icons/django-svgrepo-com.svg'), type: 'Backend' },
      { name: 'Flask', icon: require('@/assets/icons/flask-svgrepo-com.svg'), type: 'Backend' },
      { name: 'Fast API', icon: require('@/assets/icons/fastapi-1.svg'), type: 'Backend' },
      { name: 'Python', icon: require('@/assets/icons/python-svgrepo-com.svg'), type: 'Backend' },
      { name: 'Node', icon: require('@/assets/icons/nodejs02-svgrepo-com.svg'), type: 'Backend' },
      { name: 'Mongo', icon: require('@/assets/icons/mongodb-svgrepo-com.svg'), type: 'Backend' },
      { name: 'Golang', icon: require('@/assets/icons/go-gopher-svgrepo-com.svg'), type: 'Backend' },
      // Infrastructure Technologies
      { name: 'AWS', icon: require('@/assets/icons/aws-svgrepo-com.svg'), type: 'Infrastructure' },
      { name: 'Azure', icon: require('@/assets/icons/azure-svgrepo-com.svg'), type: 'Infrastructure' },
      { name: 'Digital Ocean', icon: require('@/assets/icons/digital-ocean-svgrepo-com.svg'), type: 'Infrastructure' },
      { name: 'Docker', icon: require('@/assets/icons/docker-svgrepo-com.svg'), type: 'Infrastructure' },
      { name: 'Kubernetes', icon: require('@/assets/icons/kubernetes-svgrepo-com.svg'), type: 'Infrastructure' },
      { name: 'Bash', icon: require('@/assets/icons/gnubash-svgrepo-com.svg'), type: 'Infrastructure' },
      // Frontend Technologies
      { name: 'React', icon: require('@/assets/icons/react-16-svgrepo-com.svg'), type: 'Frontend' },
      { name: 'Vue', icon: require('@/assets/icons/vue-9-logo-svgrepo-com.svg'), type: 'Frontend' },
    ],
      currentType: 'Backend',
      backgroundColors: {
        'Backend': '#023e8a',
        'Infrastructure': '#dab600',
        'Frontend': '#028d8a',
        // ... more background colors
      },
      breakpoints: {
                // 700px and up
                0:{

                    itemsToShow: 1,
                    itemsToScroll:1,
                    // snapAlign: 'center',
                },
                900: {
                    itemsToShow: 9,
                    itemsToScroll:1,
                    // snapAlign: 'center',
                },
                // 1024 and up
                1300: {
                    itemsToShow: 11,
                    itemsToScroll:1,
                    spacePadding: 0
                    // snapAlign: 'center',
                },
            },
     
      // ... other data properties
    };
  },
  computed: {
   
    currentTechnologies() {
    // Assuming this.currentType holds the current technology type like 'Backend', 'Infrastructure', 'Frontend'
    return this.technologies.filter(tech => tech.type === this.currentType);
  },
  computedStyle() {
      // Use the current background color based on the current type
      const backgroundColor = this.backgroundColors[this.currentType];
      // Return the CSS variable style as an object
      return {
        '--main-bg-color': backgroundColor
      };
    },
    // ... other computed properties
  },
  methods: {
     onSlideChange(newIndex) {
      const newActiveSlide = this.technologies[newIndex];
      this.currentType = newActiveSlide ? newActiveSlide.type : null;
    }
    // ... other methods
  }
};
</script>

<style scoped>
/* Scoped styles here */
.slide {
  padding: 0; /* Adjust or remove padding */
  margin: 0; /* Adjust or remove margin */
}
  .carousel__slide {
    padding: 0px!important;
    
    /* padding-bottom: 50px!important; */

    /* @apply pb-40 xl:pb-0; */
    /* overflow: visible!important; */
    
  }
.carousel__slide--active div img {
  /* Add your styles here */
    width: 180px; /* Example: make the image take up 100% of its parent's width */
    height: auto; /* Example: scale the height automatically to maintain the aspect ratio */
    object-fit: cover; /* Example: cover the area without distorting the aspect ratio */
    border-radius: 10px; /* Example: give the image rounded corners */
}
.currentBackground {
  /* --main-bg-color: #61ad1e; Define a CSS variable for the main background color */
  position: relative;
  min-height: 50vh; /* Adjust the height as needed */
  background-color: var(--main-bg-color); /* Use the CSS variable for the background color */
  overflow: hidden;
}

.currentBackground::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.155); /* White with transparency */
  clip-path: polygon(8% 0, 100% 0, 100% 100%,92% 100%);
  z-index: 1;
}

.carousel {
  position: relative;
  z-index: 3;
  /* Your styles for the carousel */
}

</style>

