// router.js
import { createRouter, createWebHistory } from 'vue-router'
import PortfolioComponent from './components/Portfolio.vue'
// import ProjectDetail from './components/ProjectDetail.vue'
import Resume from './components/Resume.vue'
import Docs from './components/Docs.vue'

const routes = [
  // { path: '/', component: ProjectDetail },
  { path: '/', component: PortfolioComponent },
  { path: '/resume', component: Resume },
  // { path: '/docs', component: Docs },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
